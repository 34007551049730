const hostname = "nxdxbkj.org.cn";
const domain = process.env.NODE_ENV === `development` ? "localhost" : `.${hostname}`;
const projectHosts = {
  web: 'web',  // pc主站
  admin: 'admin',  // 交易系统
  trade: 'admin',
  data: 'data',  // dataServer
  cms: 'cms',  // 楼层
  shenbao: 'shenbao', // 申报
  qxt: 'qxt',  // 企校通
  service: 'service', // 科技服务
  seller: 'seller' // 卖家中心
}
export {
  hostname,
  domain,
  projectHosts
};